<template>
    <div class="loading-screen">
        <div class="gear-container">
            <div class="gear"></div>
        </div>
        <p>Carregando dados, por favor aguarde...</p>
    </div>
</template>

<script>
export default {
    name: 'LoadingScreen',
};
</script>

<style scoped>
.loading-screen {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: 100vh;
    font-size: 1.5em;
    margin-top: -20px; /** pra ficar mais pra cima **/
}

.gear-container {
    margin-bottom: 20px;
}

.gear {
    width: 100px;
    height: 100px;
    border: 15px solid #eee;
    border-bottom-color: #0d6efd;
    border-radius: 50%;
    animation: rotate 1.5s linear infinite;
}

@keyframes rotate {
    from {
        transform: rotate(0deg);
    }

    to {
        transform: rotate(360deg);
    }
}
</style>