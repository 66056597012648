<template>
    <div>
      <Header @toggle-sidebar="toggleSidebar" />
      <div id="layoutSidenav">
        <Navbar />
        <div id="layoutSidenav_content">
          <main>
            <div class="container-fluid px-4">
              CONTEUDO DA PAGINA
            </div>
          </main>
          <Footer />
        </div>
      </div>
    </div>
  </template>
  
  <script>
  import Header from '@/components/Header.vue';
  import Navbar from '@/components/Navbar.vue';
  import Footer from '@/components/Footer.vue';
  
  export default {
    name: 'BlankPage',
    components: {
      Header,
      Navbar,
      Footer,
    },
    data() {
      return {
        isToggled: false,
      };
    },
    methods: {
      toggleSidebar() {
        document.body.classList.toggle('sb-sidenav-toggled');
        localStorage.setItem('sb|sidebar-toggle', document.body.classList.contains('sb-sidenav-toggled'));
      }
    }
  };
  </script>
  
  <style scoped>
  /* Adicione estilos específicos do componente aqui */
  </style>
  