<template>
  <div>
    <Header @toggle-sidebar="toggleSidebar" />
    <div id="layoutSidenav">
      <Navbar />
      <div id="layoutSidenav_content">
        <main>
          <LoadingScreen v-if="loading" />    
          
          <router-link class="nav-link" to="/clientes-form"><div class="sb-nav-link-icon"><i class="fas fa-briefcase"></i></div>Novo cliente</router-link>

          <div v-show="!loading" class="container mt-4">
            <h1 class="mb-4">Clientes</h1>

            <div class="card mb-4">
              <div class="card-header">
                <i class="fas fa-table me-1"></i>
                  Clientes
              </div>
              <div class="card-body">
                <table ref="datatablesSimple" class="table table-striped table-bordered">
                  <thead>
                    <tr>
                      <th>Nome</th>
                      <th>Documento</th>
                      <th>Telefone</th>
                      <th>Email</th>
                      <th>Ativo</th>
                      <th>Inadimplente</th>
                    </tr>
                  </thead>
                  <tfoot>
                    <tr>
                      <th>Nome</th>
                      <th>Documento</th>
                      <th>Telefone</th>
                      <th>Email</th>
                      <th>Ativo</th>
                      <th>Inadimplente</th>
                    </tr>
                  </tfoot>
                  <tbody>
                    <tr v-for="cliente in clientes" :key="cliente.id">
                      <td>{{ cliente.nm_cliente }}</td>
                      <td>{{ cliente.nr_documento }}</td>
                      <td>{{ cliente.nr_telefone_1 }}</td>
                      <td>{{ cliente.ds_email }}</td>
                      <td>{{ cliente.fl_ativo ? 'Sim' : 'Não' }}</td>
                      <td>{{ cliente.fl_inadimplente ? 'Sim' : 'Não' }}</td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </main>
        <Footer />
      </div>
    </div>
  </div>
</template>

<script>
import Header from '@/components/Header.vue';
import Navbar from '@/components/Navbar.vue';
import Footer from '@/components/Footer.vue';

import axios from '@/plugins/axios';
import { DataTable } from 'simple-datatables';
import LoadingScreen from '@/components/LoadingScreen.vue';

export default {
  name: 'ClienteView',
  components: {
    Header,
    Navbar,
    Footer,
    LoadingScreen
  },
  data() {
    return {
      clientes: [],
      loading: true
    };
  },
  created() {
    this.fetchClientes();
  },
  methods: {
    async fetchClientes() {
      try {
        const response = await axios.get(`${process.env.VUE_APP_URL_API}/api/cadastro/cliente`);
        this.clientes = response.data;
        this.loading = false;
        this.$nextTick(() => {
          this.initializeDataTable();
        });
      } catch (error) {
        console.error('Erro ao buscar clientes:', error);
      }
    },
    initializeDataTable() {
      const datatablesSimple = this.$refs.datatablesSimple;
      if (datatablesSimple) {
        console.log('Initializing DataTable...');
        new DataTable(datatablesSimple);
      } else {
        console.error('datatablesSimple element not found');
      }
    },
  },
};
</script>

<style>

</style>