// src/plugins/axios.js
import axios from 'axios';
import store from '../store';
import router from '../router';

const instance = axios.create({
  baseURL: process.env.VUE_APP_API_BASE_URL,
});

// Interceptor de requisição para adicionar o token JWT ao cabeçalho de autorização
instance.interceptors.request.use(
  config => {
    const token = localStorage.getItem('idToken');
    if (token) {
      config.headers.Authorization = `Bearer ${token}`;
    }
    return config;
  },
  error => {
    return Promise.reject(error);
  }
);

// Interceptor de resposta para lidar com respostas 401 e 403
instance.interceptors.response.use(
  response => response,
  error => {
    if (error.response && (error.response.status === 401 || error.response.status === 403)) {
      console.log("Interceptor - API status 401 or 403!");
      store.commit('logout');
      router.push({ name: 'Login' });
    }
    return Promise.reject(error);
  }
);

export default instance;
