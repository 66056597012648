<template>
  <header>
    <nav class="sb-topnav navbar navbar-expand navbar-dark bg-dark">
      <a class="navbar-brand ps-3" href="#">PetSys</a>
      <button class="btn btn-link btn-sm order-1 order-lg-0 me-4 me-lg-0" id="sidebarToggle" ref="sidebarToggle"><i class="fas fa-bars"></i></button>
      <form class="d-none d-md-inline-block form-inline ms-auto me-0 me-md-3 my-2 my-md-0">
        <div class="input-group">
          <input class="form-control" type="text" placeholder="Pesquisar cliente..." aria-label="Pesquisar cliente..." aria-describedby="btnNavbarSearch" />
          <button class="btn btn-primary" id="btnNavbarSearch" type="button"><i class="fas fa-search"></i></button>
        </div>
      </form>
      <ul class="navbar-nav ms-auto ms-md-0 me-3 me-lg-4">
        <li class="nav-item dropdown">
          <a class="nav-link dropdown-toggle" id="navbarDropdown" href="#" role="button" data-bs-toggle="dropdown" aria-expanded="false"><i class="fas fa-user fa-fw"></i></a>
          <ul class="dropdown-menu dropdown-menu-end" aria-labelledby="navbarDropdown">
            <li><a class="dropdown-item" href="#">Meu perfil</a></li>
            <li><hr class="dropdown-divider" /></li>
            <li><router-link class="dropdown-item" to="/login">Logout</router-link></li>
          </ul>
        </li>
      </ul>
    </nav>
  </header>
</template>

<script>
// import { onAuthStateChanged } from "firebase/auth";
// import { auth } from "../firebase"; // Importa a instância do auth do arquivo firebase.js
import axios from 'axios';
import { onMounted } from 'vue';
import { useStore } from 'vuex';
import { useRouter } from 'vue-router';

export default {
  name: 'Header',
  data() {
    return {
      user: null,
      isAuthenticated: false,
    };
  },  
  methods: {
    initializeSidebarToggle() {
      const sidebarToggle = document.body.querySelector('#sidebarToggle');
      if (sidebarToggle) {
        if (localStorage.getItem('sb|sidebar-toggle') === 'true') {
          document.body.classList.add('sb-sidenav-toggled');
        }
        sidebarToggle.addEventListener('click', event => {
          event.preventDefault();
          document.body.classList.toggle('sb-sidenav-toggled');
          localStorage.setItem('sb|sidebar-toggle', document.body.classList.contains('sb-sidenav-toggled'));
        });
      }
    },    
    toggleSidebar() {
      const sidebarToggle = this.$refs.sidebarToggle;
      if (sidebarToggle) {
        sidebarToggle.click();
      }
    }
  },
  setup(){
    const store = useStore();
    const router = useRouter();
    
    onMounted(() => {
      store.dispatch('validateToken').catch(() => {
        console.log("router.push -> login");
        router.push('/login');
      });

    let token = localStorage.getItem("idToken");
    axios.get(`${process.env.VUE_APP_URL_API}/api/sistema/validatejwt?token=${token}`)
      .then(response => {
        const data = response.data;
        if (data.status != 200){
          router.push('/login');
        }
      }).catch(error => {
        console.error('Erro ao fazer a requisição:', error);
        router.push('/login');
      });
    });        
  },
  mounted(){
    this.initializeSidebarToggle();
    this.$router.afterEach(() => {
      this.initializeSidebarToggle();
    });  
    document.title = "PetSys - Gestão de Pet Shop | Banho & Tosa | Consultório Veterinário";  
  }
};
</script>

<style scoped>
@import url('https://use.fontawesome.com/releases/v6.3.0/css/all.css');
@import url('https://cdn.jsdelivr.net/npm/bootstrap@5.2.3/dist/css/bootstrap.min.css');
/* Adicione estilos específicos do componente aqui */
</style>
