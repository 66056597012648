import { createRouter, createWebHistory } from "vue-router";
import LoginView from "../views/LoginView.vue";
import BlankPage from '@/views/BlankPage.vue';
import BlankPage2 from '@/views/BlankPage2.vue';
import ClienteView from '@/views/ClienteView.vue';
import ClienteForm from "@/views/ClienteForm.vue";

const routes = [
  {
    path: "/login",
    name: "LoginView",
    component: LoginView,
  },  
  {
    path: '/',
    name: 'BlankPage',
    component: BlankPage,
  },
  {
    path: '/blank2',
    name: 'BlankPage2',
    component: BlankPage2,
  },
  {
    path: '/clientes',
    name: 'ClienteView',
    component: ClienteView
  },
  {
    path: '/clientes-form',
    name: 'ClienteForm',
    component: ClienteForm
  },  
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
  linkActiveClass: "active",
});

export default router;