<template>
  <footer class="py-4 bg-light mt-auto">
    <div class="container-fluid px-4">
      <div class="d-flex align-items-center justify-content-between small">
        <div class="text-muted"><b>{{ this.tenant }}</b></div>
        <div>
            <a href="https://www.datatrading.com.br">datatrading.com.br</a>
            &middot;
            <a href="mailto:contato@datatrading.com.br">contato@datatrading.com.br</a>
        </div>
      </div>
    </div>
  </footer>
</template>

<script>
import axios from '@/plugins/axios';

export default {
  name: 'Footer',
  data() {
    return {
      tenant: ''
    }
  },
  async mounted() {
    const response = await axios.get(`${process.env.VUE_APP_URL_API}/api/sistema/tenant`);
    this.tenant = response.data[0].nm_tenant;
  }   
};
</script>

<style scoped>
/* Adicione estilos específicos do componente aqui */
</style>
