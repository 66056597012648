<template>
  <div>
    <router-view />
  </div>
</template>

<script>
export default {
  name: 'App',
  methods: {
    initializeSidebarToggle() {
      const sidebarToggle = document.body.querySelector('#sidebarToggle');
      if (sidebarToggle) {
        if (localStorage.getItem('sb|sidebar-toggle') === 'true') {
          document.body.classList.add('sb-sidenav-toggled');
        }
        sidebarToggle.addEventListener('click', event => {
          event.preventDefault();
          document.body.classList.toggle('sb-sidenav-toggled');
          localStorage.setItem('sb|sidebar-toggle', document.body.classList.contains('sb-sidenav-toggled'));
        });
      }
    }
  },
  mounted() {
    this.initializeSidebarToggle();
    this.$router.afterEach(() => {
      this.initializeSidebarToggle();
    });
  }    
};
</script>

<style>
/* Adicione estilos específicos do componente aqui */
</style>
