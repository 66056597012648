import { createApp } from 'vue';
import App from './App.vue';
import store from './store';
import router from './router';
import axios from './plugins/axios';

// Estilos
import 'simple-datatables/dist/style.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import './assets/css/styles.css';

// JavaScripts
import 'bootstrap/dist/js/bootstrap.bundle.min.js';
import './assets/js/scripts.js';
import jQuery from 'jquery';

window.$ = window.jQuery = jQuery;

const app = createApp(App);

// Função para carregar scripts externos
function loadScript(src, crossorigin) {
  return new Promise((resolve, reject) => {
    const script = document.createElement('script');
    script.src = src;
    if (crossorigin) {
      script.crossOrigin = crossorigin;
    }
    script.onload = () => resolve();
    script.onerror = () => reject(new Error(`Failed to load script ${src}`));
    document.head.appendChild(script);
  });
}

// Lista de scripts a serem carregados
const scripts = [
  { src: 'https://use.fontawesome.com/releases/v6.3.0/js/all.js', crossorigin: 'anonymous' },
  { src: 'https://cdn.jsdelivr.net/npm/simple-datatables@7.1.2/dist/umd/simple-datatables.min.js', crossorigin: "anonymous" },
  { src: 'https://use.fontawesome.com/releases/v6.3.0/js/all.js', crossorigin: 'anonymous' }
];

// Carregar todos os scripts sequencialmente
(async () => {
  try {
    for (const { src, crossorigin } of scripts) {
      await loadScript(src, crossorigin);
    }
    console.log('Todos os scripts foram carregados');
  } catch (error) {
    console.error('Erro ao carregar scripts:', error);
  }
})();

// // Importar Font Awesome
// const script = document.createElement('script');
// script.src = 'https://use.fontawesome.com/releases/v6.3.0/js/all.js';
// script.crossOrigin = 'anonymous';
// document.head.appendChild(script);

app.config.globalProperties.$axios = axios;

app.use(store);
app.use(router);
app.mount('#app');