<template>
    <aside id="layoutSidenav_nav">
      <nav class="sb-sidenav accordion sb-sidenav-dark" id="sidenavAccordion">
        <div class="sb-sidenav-menu">
          <div class="nav">
  
            <div class="sb-sidenav-menu-heading">Atendimento</div>

            <a class="nav-link collapsed" href="#" data-bs-toggle="collapse" data-bs-target="#collapseAtendimento" aria-expanded="false" aria-controls="collapseAtendimento">
              <div class="sb-nav-link-icon"><i class="fas fa-pen-to-square"></i></div>
              Ordem de Serviço
            </a>

            <a class="nav-link collapsed" href="#" data-bs-toggle="collapse" data-bs-target="#collapseCadastro" aria-expanded="false" aria-controls="collapseCadastro">
              <div class="sb-nav-link-icon"><i class="fas fa-user"></i></div>
              Cliente
              <div class="sb-sidenav-collapse-arrow"><i class="fas fa-angle-down"></i></div>
            </a>
            <div class="collapse" id="collapseCadastro" aria-labelledby="headingTwo" data-bs-parent="#sidenavAccordion">
              <nav class="sb-sidenav-menu-nested nav accordion" id="sidenavAccordionPages">
                <router-link class="nav-link" to="/clientes"><div class="sb-nav-link-icon"><i class="fas fa-face-smile"></i></div>Gerenciar cliente</router-link>
                <router-link class="nav-link" to="/contrato"><div class="sb-nav-link-icon"><i class="fas fa-briefcase"></i></div>Contrato de serviço</router-link>
              </nav>
            </div>            

            <a class="nav-link collapsed" href="#" data-bs-toggle="collapse" data-bs-target="#collapseVeterinario" aria-expanded="false" aria-controls="collapseVeterinario">
              <div class="sb-nav-link-icon"><i class="fas fa-paw"></i></div>
              Veterinário
              <div class="sb-sidenav-collapse-arrow"><i class="fas fa-angle-down"></i></div>
            </a>
            <div class="collapse" id="collapseVeterinario" aria-labelledby="headingTwo" data-bs-parent="#sidenavAccordion">
              <nav class="sb-sidenav-menu-nested nav accordion" id="sidenavAccordionPages">
                <router-link class="nav-link" to="/atendimento"><div class="sb-nav-link-icon"><i class="fas fa-stethoscope"></i></div>Atendimento</router-link>
                <router-link class="nav-link" to="/prontuario"><div class="sb-nav-link-icon"><i class="fas fa-clipboard-list"></i></div>Prontuário</router-link>
                <router-link class="nav-link" to="/receituario"><div class="sb-nav-link-icon"><i class="fas fa-prescription-bottle-medical"></i></div>Receituário</router-link>
              </nav>
            </div>            


            <div class="sb-sidenav-menu-heading">Sistema</div>

            <a class="nav-link collapsed" href="#" data-bs-toggle="collapse" data-bs-target="#collapseSistemaCadastro" aria-expanded="false" aria-controls="collapseSistemaCadastro">
              <div class="sb-nav-link-icon"><i class="fas fa-pen-to-square"></i></div>
              Cadastro
              <div class="sb-sidenav-collapse-arrow"><i class="fas fa-angle-down"></i></div>
            </a>

            <div class="collapse" id="collapseSistemaCadastro" aria-labelledby="headingTwo" data-bs-parent="#sidenavAccordion">
              <nav class="sb-sidenav-menu-nested nav accordion" id="sidenavAccordionPages">
                <router-link class="nav-link" to="/produto"><div class="sb-nav-link-icon"><i class="fas fa-gear"></i></div>Serviço</router-link>                
                <router-link class="nav-link" to="/servicos"><div class="sb-nav-link-icon"><i class="fas fa-box-open"></i></div>Produto</router-link>
                <router-link class="nav-link" to="/pet"><div class="sb-nav-link-icon"><i class="fas fa-dog"></i></div>Pet</router-link>
                <router-link class="nav-link" to="/vacina"><div class="sb-nav-link-icon"><i class="fas fa-syringe"></i></div>Vacina</router-link>
              </nav>
            </div>           



            <div class="sb-sidenav-menu-heading">Gerencial</div>

            <a class="nav-link collapsed" href="#" data-bs-toggle="collapse" data-bs-target="#collapseGerencialRelatorio" aria-expanded="false" aria-controls="collapseGerencialRelatorio">
              <div class="sb-nav-link-icon"><i class="fas fa-paperclip"></i></div>
              Relatórios
              <div class="sb-sidenav-collapse-arrow"><i class="fas fa-angle-down"></i></div>
            </a>

            <div class="collapse" id="collapseGerencialRelatorio" aria-labelledby="headingTwo" data-bs-parent="#sidenavAccordion">
              <nav class="sb-sidenav-menu-nested nav accordion" id="sidenavAccordionPages">
                <router-link class="nav-link" to="/planos-ativos"><div class="sb-nav-link-icon"><i class="fas fa-heart"></i></div>Planos ativos</router-link>                
              </nav>
            </div> 
            
            <a class="nav-link collapsed" href="#" data-bs-toggle="collapse" data-bs-target="#collapseGerencialDashboard" aria-expanded="false" aria-controls="collapseGerencialDashboard">
              <div class="sb-nav-link-icon"><i class="fas fa-chart-line"></i></div>
              Dashboards
              <div class="sb-sidenav-collapse-arrow"><i class="fas fa-angle-down"></i></div>
            </a>

            <div class="collapse" id="collapseGerencialDashboard" aria-labelledby="headingTwo" data-bs-parent="#sidenavAccordion">
              <nav class="sb-sidenav-menu-nested nav accordion" id="sidenavAccordionPages">
                <router-link class="nav-link" to="/painel-contratos"><div class="sb-nav-link-icon"><i class="fas fa-rocket"></i></div>Performance</router-link>                
                <router-link class="nav-link" to="/painel-ordem-servico"><div class="sb-nav-link-icon"><i class="fas fa-bars-staggered"></i></div>Quadro de serviços</router-link>                
              </nav>
            </div>             

            <!--
            <div class="sb-sidenav-menu-heading">Interface</div>


            <a class="nav-link collapsed" href="#" data-bs-toggle="collapse" data-bs-target="#collapseLayouts" aria-expanded="false" aria-controls="collapseLayouts">
              <div class="sb-nav-link-icon"><i class="fas fa-columns"></i></div>
              Layouts
              <div class="sb-sidenav-collapse-arrow"><i class="fas fa-angle-down"></i></div>
            </a>

            <div class="collapse" id="collapseLayouts" aria-labelledby="headingOne" data-bs-parent="#sidenavAccordion">
              <nav class="sb-sidenav-menu-nested nav">
                <a class="nav-link" href="/blank2">Blank - HREF</a>
                <router-link class="nav-link" to="/blank2">Blank Router Link</router-link>
              </nav>
            </div>
            <a class="nav-link collapsed" href="#" data-bs-toggle="collapse" data-bs-target="#collapsePages" aria-expanded="false" aria-controls="collapsePages">
              <div class="sb-nav-link-icon"><i class="fas fa-book-open"></i></div>
              Pages
              <div class="sb-sidenav-collapse-arrow"><i class="fas fa-angle-down"></i></div>
            </a>
            <div class="collapse" id="collapsePages" aria-labelledby="headingTwo" data-bs-parent="#sidenavAccordion">
              <nav class="sb-sidenav-menu-nested nav accordion" id="sidenavAccordionPages">
                <a class="nav-link collapsed" href="#" data-bs-toggle="collapse" data-bs-target="#pagesCollapseAuth" aria-expanded="false" aria-controls="pagesCollapseAuth">
                  Authentication
                  <div class="sb-sidenav-collapse-arrow"><i class="fas fa-angle-down"></i></div>
                </a>
                <div class="collapse" id="pagesCollapseAuth" aria-labelledby="headingOne" data-bs-parent="#sidenavAccordionPages">
                  <nav class="sb-sidenav-menu-nested nav">
                    <a class="nav-link" href="#">Login</a>
                    <a class="nav-link" href="#">Register</a>
                    <a class="nav-link" href="#">Forgot Password</a>
                  </nav>
                </div>
                <a class="nav-link collapsed" href="#" data-bs-toggle="collapse" data-bs-target="#pagesCollapseError" aria-expanded="false" aria-controls="pagesCollapseError">
                  Error
                  <div class="sb-sidenav-collapse-arrow"><i class="fas fa-angle-down"></i></div>
                </a>
                <div class="collapse" id="pagesCollapseError" aria-labelledby="headingOne" data-bs-parent="#sidenavAccordionPages">
                  <nav class="sb-sidenav-menu-nested nav">
                    <a class="nav-link" href="#">401 Page</a>
                    <a class="nav-link" href="#">404 Page</a>
                    <a class="nav-link" href="#">500 Page</a>
                  </nav>
                </div>
              </nav>
            </div>
            <div class="sb-sidenav-menu-heading">Addons</div>
            <a class="nav-link" href="#">
              <div class="sb-nav-link-icon"><i class="fas fa-chart-area"></i></div>
              Charts
            </a>
            <a class="nav-link" href="#">
              <div class="sb-nav-link-icon"><i class="fas fa-table"></i></div>
              Tables
            </a>
            -->
          </div>
        </div>
        <div class="sb-sidenav-footer">
          <div class="small">Conectado como: </div>
          {{ this.user }}
        </div>
      </nav>
    </aside>
  </template>
  
  <script>
  export default {
    name: 'Navbar',
    data() {
      return {
          user: ''
      };
    },
    mounted() {
      this.user = localStorage.getItem("user");
    } 
  };
  </script>
  
  <style scoped>
  /* Adicione estilos específicos do componente aqui */
  </style>
  