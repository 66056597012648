<template>
    <div>
        <Header @toggle-sidebar="toggleSidebar" />
        <div id="layoutSidenav">
            <Navbar />
            <div id="layoutSidenav_content">
                <main>
                    <div class="container-fluid px-4">
                        <h1 v-show="isEditing">{{ this.form.nm_cliente }}</h1>
                        <div class="card shadow-lg border-0 rounded-lg mt-5">
                            <div class="card-header">
                                <h3 class="text-left font-weight-light my-4">
                                    {{ isEditing ? "Editar cliente" : "Novo cliente" }}
                                </h3>
                            </div>
                            <div class="card-body">
                                <form @submit.prevent="onSubmit">
                                    <div class="row mb-3">
                                        <div class="col-md-4 d-flex align-items-center">
                                            <div class="form-check me-3">
                                                <input class="form-check-input" type="radio" name="tipoCliente"
                                                    id="tipoClientePF" value="1" v-model="form.cd_tipo_cliente"
                                                    @change="updateDocumentoPlaceholder" checked />
                                                <label class="form-check-label" for="tipoClientePF">
                                                    Pessoa física
                                                </label>
                                            </div>
                                            <div class="form-check">
                                                <input class="form-check-input" type="radio" name="tipoCliente"
                                                    id="tipoClientePJ" value="2" v-model="form.cd_tipo_cliente"
                                                    @change="updateDocumentoPlaceholder" />
                                                <label class="form-check-label" for="tipoClientePJ">
                                                    Pessoa jurídica
                                                </label>
                                            </div>
                                        </div>
                                        <div class="col-md-4">
                                            <div class="form-floating mb-3 mb-md-0">
                                                <input class="form-control" id="nr_documento" type="text"
                                                    v-model="form.nr_documento" :placeholder="documentoPlaceholder"
                                                    @input="formatDocumento" :maxlength="documentoMaxLength" required />
                                                <label for="nr_documento">{{
                                                    documentoPlaceholder
                                                    }}</label>
                                            </div>
                                        </div>
                                        <div class="col-md-4">
                                            <div class="form-floating">
                                                <input class="form-control" id="dt_nascimento" type="date"
            v-model="form.dt_nascimento" @blur="formatDateForDisplay"
            @focus="showDatePicker" placeholder="dd/mm/yyyy" />
        <label for="dt_nascimento">Data de nascimento</label>
                                                </div>
                                        </div>
                                    </div>
                                    <div class="row mb-3">
                                        <div class="col-md-6">
                                            <div class="form-floating mb-3 mb-md-0">
                                                <input class="form-control" id="nm_cliente" type="text"
                                                    v-model="form.nm_cliente" placeholder="Nome do Cliente" required />
                                                <label for="nm_cliente">Nome do cliente</label>
                                            </div>
                                        </div>
                                        <div class="col-md-3">
                                            <div class="form-floating">
                                                <input class="form-control" id="nr_telefone_1" type="text"
                                                    v-model="form.nr_telefone_1" @input="formatTelefone1"
                                                    :maxlength="telefoneMaxLength" placeholder="Telefone 1" required />
                                                <label for="nr_telefone_1">Telefone #1</label>
                                            </div>
                                        </div>
                                        <div class="col-md-3">
                                            <div class="form-floating">
                                                <input class="form-control" id="nr_telefone_2" type="text"
                                                    v-model="form.nr_telefone_2" @input="formatTelefone2"
                                                    :maxlength="telefoneMaxLength" placeholder="Telefone 2" />
                                                <label for="nr_telefone_2">Telefone #2</label>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="row mb-3">
                                        <div class="col-md-3">
                                            <div class="form-floating mb-3 mb-md-0">
                                                <input class="form-control" id="nr_cep" type="text"
                                                    v-model="form.nr_cep" @input="handleCepInput" @blur="validateCep"
                                                    maxlength="10" placeholder="CEP" />
                                                <label for="nr_cep">CEP</label>
                                            </div>
                                        </div>
                                        <div class="col-md-6">
                                            <div class="form-floating">
                                                <input class="form-control" :readonly="isCepValid"
                                                    id="ds_endereco"
                                                    type="text" v-model="form.ds_endereco" placeholder="Endereço" required />
                                                <label for="ds_endereco">Endereço</label>
                                            </div>
                                        </div>
                                        <div class="col-md-3">
                                            <div class="form-floating">
                                                <input class="form-control" ref="nr_endereco" id="nr_endereco"
                                                    type="text" v-model="form.nr_endereco" placeholder="Número" />
                                                <label for="nr_endereco">Número</label>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="form-floating mb-3">
                                        <input class="form-control" id="ds_complemento" type="text"
                                            v-model="form.ds_complemento" placeholder="Complemento" />
                                        <label for="ds_complemento">Complemento</label>
                                    </div>
                                    <div class="row mb-3">
                                        <div class="col-md-4">
                                            <div class="form-floating mb-3 mb-md-0">
                                                <input class="form-control" :readonly="isCepValid"
                                                    id="nm_bairro" type="text"
                                                    v-model="form.nm_bairro" placeholder="Bairro" required />
                                                <label for="nm_bairro">Bairro</label>
                                            </div>
                                        </div>
                                        <div class="col-md-4">
                                            <div class="form-floating">
                                                <input class="form-control" :readonly="isCepValid"
                                                    id="nm_cidade" type="text"
                                                    v-model="form.nm_cidade" placeholder="Cidade" required />
                                                <label for="nm_cidade">Cidade</label>
                                            </div>
                                        </div>
                                        <div class="col-md-4">
                                            <div class="form-floating">
                                                <select class="form-control" id="cd_uf"
                                                    v-model="form.cd_uf" required>
                                                    <option v-for="uf in ufs" :key="uf.cd_uf" :value="uf.cd_uf">
                                                        {{ uf.nm_uf }}
                                                    </option>
                                                </select>
                                                <label for="cd_uf">UF</label>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="row mb-3">
                                        <div class="col-md-9">
                                            <div class="form-floating">
                                                <input class="form-control" id="ds_email" type="email"
                                                    v-model="form.ds_email" placeholder="Email" />
                                                <label for="ds_email">E-mail</label>
                                            </div>
                                        </div>
                                        <div class="col-md-3">
                                            <div class="form-floating">
                                                <input class="form-control" id="nr_contrato" type="text"
                                                    v-model="form.nr_contrato" placeholder="Contrato" />
                                                <label for="nr_contrato">Contrato</label>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="form-floating mb-3">
                                        <textarea class="form-control" id="ds_obs" v-model="form.ds_obs"
                                            placeholder="Observações"
                                            style="height: 100px"></textarea>
                                        <label for="ds_obs">Observações</label>
                                    </div>
                                    <div class="form-check mb-3">
                                        <input class="form-check-input" id="fl_inadimplente" type="checkbox"
                                            v-model="form.fl_inadimplente" />
                                        <label class="form-check-label" for="fl_inadimplente">Inadimplente</label>
                                    </div>
                                    <div class="form-check mb-3">
                                        <input class="form-check-input" id="fl_ativo" type="checkbox"
                                            v-model="form.fl_ativo" />
                                        <label class="form-check-label" for="fl_ativo">Ativo</label>
                                    </div>
                                    <div class="mt-4 mb-0">
                                        <div class="d-grid">
                                            <button class="btn btn-primary btn-block" type="submit">
                                                {{ isEditing ? "Atualizar" : "Salvar" }}
                                            </button>
                                        </div>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                </main>
                <Footer />
            </div>
        </div>
    </div>
</template>

<script>
import axios from '@/plugins/axios';
import Header from "@/components/Header.vue";
import Navbar from "@/components/Navbar.vue";
import Footer from "@/components/Footer.vue";

export default {
    name: "ClienteForm",
    components: {
        Header,
        Navbar,
        Footer,
    },
    data() {
        return {
            isToggled: false,
            isEditing: false,
            documentoPlaceholder: "CPF",
            documentoMaxLength: 14,
            telefoneMaxLength: 15,
            isCepValid: false,
            ufs: [],
            form: {
                id: "",
                nm_cliente: "",
                cd_tipo_cliente: "1", // Pessoa Física por padrão
                nr_documento: "",
                nr_telefone_1: "",
                nr_telefone_2: "",
                dt_nascimento: "",
                ds_endereco: "",
                nr_endereco: "",
                ds_complemento: "",
                nm_bairro: "",
                nm_cidade: "",
                cd_uf: "SC",
                nr_cep: "",
                ds_obs: "",
                ds_email: "",
                fl_inadimplente: false,
                fl_ativo: true,
                nr_contrato: "",
            },
        };
    },
    methods: {
        updateDocumentoPlaceholder() {
            this.form.nr_documento = "";
            if (this.form.cd_tipo_cliente === "1") {
                this.documentoPlaceholder = "CPF";
                this.documentoMaxLength = 14;
            } else {
                this.documentoPlaceholder = "CNPJ";
                this.documentoMaxLength = 18;
            }
        },
        formatDocumento() {
            let value = this.form.nr_documento.replace(/\D/g, "");
            if (this.form.cd_tipo_cliente === "1") {
                // Format CPF
                value = value.replace(/(\d{3})(\d)/, "$1.$2");
                value = value.replace(/(\d{3})(\d)/, "$1.$2");
                value = value.replace(/(\d{3})(\d{1,2})$/, "$1-$2");
            } else {
                // Format CNPJ
                value = value.replace(/(\d{2})(\d)/, "$1.$2");
                value = value.replace(/(\d{3})(\d)/, "$1.$2");
                value = value.replace(/(\d{3})(\d)/, "$1/$2");
                value = value.replace(/(\d{4})(\d{1,2})$/, "$1-$2");
            }
            this.form.nr_documento = value;
        },
        handleCepInput() {
            this.formatCEP();
            if (this.form.nr_cep.replace(/\D/g, "").length === 8) {
                this.fetchEndereco();
            }
        },
        formatCEP() {
            let value = this.form.nr_cep.replace(/\D/g, "");
            value = value.replace(/(\d{2})(\d)/, "$1.$2");
            value = value.replace(/(\d{3})(\d{1,3})$/, "$1-$2");
            this.form.nr_cep = value;
        },
        async fetchEndereco() {
            const cep = this.form.nr_cep.replace(/\D/g, "");
            if (cep.length === 8) {
                try {
                    const response = await fetch(`https://viacep.com.br/ws/${cep}/json/`);
                    const data = await response.json();
                    if (!data.erro) {
                        this.form.ds_endereco = data.logradouro.toUpperCase();
                        this.form.nm_bairro = data.bairro.toUpperCase();
                        this.form.nm_cidade = data.localidade.toUpperCase();
                        this.form.cd_uf = data.uf.toUpperCase();
                        this.isCepValid = true;
                        this.$nextTick(() => {
                            this.$refs.nr_endereco.focus();
                        });
                    } else {
                        this.isCepValid = false;
                        alert("CEP não encontrado");
                    }
                } catch (error) {
                    this.isCepValid = false;
                    alert("Erro ao buscar CEP");
                }
            }
        },
        validateCep() {
            if (!this.isCepValid) {
                this.isCepValid = false;
                this.clearEnderecoFields();
            }
        },
        clearEnderecoFields() {
            this.form.ds_endereco = "";
            this.form.nm_bairro = "";
            this.form.nm_cidade = "";
            this.form.cd_uf = "SC";
        },
        formatTelefone1() {
            this.formatTelefone("nr_telefone_1");
        },
        formatTelefone2() {
            this.formatTelefone("nr_telefone_2");
        },
        formatTelefone(field) {
            let value = this.form[field].replace(/\D/g, "");
            if (value.length <= 10) {
                // Format as (99) 9999-9999
                value = value.replace(/(\d{2})(\d)/, "($1) $2");
                value = value.replace(/(\d{4})(\d{1,4})$/, "$1-$2");
            } else {
                // Format as (99) 99999-9999
                value = value.replace(/(\d{2})(\d)/, "($1) $2");
                value = value.replace(/(\d{5})(\d{1,4})$/, "$1-$2");
            }
            this.form[field] = value;
        },
        formatDateForDisplay() {
            const date = new Date(this.form.dt_nascimento);
            const day = String(date.getDate()).padStart(2, '0');
            const month = String(date.getMonth() + 1).padStart(2, '0'); 
            const year = date.getFullYear();
            this.form.dt_nascimento = `${day}/${month}/${year}`;
        },
        formatDateForSaving() {
            if (this.form.dt_nascimento && this.form.dt_nascimento.includes("/")) {
                const [day, month, year] = this.form.dt_nascimento.split("/");
                this.form.dt_nascimento = `${year}-${month}-${day}`;
            }
        },
        // showDatePicker(event) {
        //     event.target.type = "date";
        //     event.target.focus();
        // },
        hideDatePicker(event) {
            event.target.type = "text";
        },
        forceUpperCase(event) {
            event.target.value = event.target.value.toUpperCase();
            this.$forceUpdate();
        },
        onSubmit() {
            this.formatDateForSaving();
            if (this.isEditing) {
                this.updateCliente();
            } else {
                this.createCliente();
            }
        },
        createCliente() {
            console.log("Creating cliente:", this.form);
        },
        updateCliente() {
            console.log("Updating cliente:", this.form);
        },
        loadCliente(id) {
            this.isEditing = true;
            // Preencher `this.form` com os dados do cliente
            // Formate a data para exibição
            this.formatDateForDisplay();
        },
        async fetchUfs() {
            try {
                const response = await axios.get(`${process.env.VUE_APP_URL_API}/api/cadastro/uf`);
                this.ufs = response.data;
            } catch (error) {
                console.error("Erro ao carregar UFs:", error);
            }
        },
    },
    mounted() {
        const clienteId = this.$route.params.id;
        if (clienteId) {
            this.loadCliente(clienteId);
        }
        this.updateDocumentoPlaceholder();
        this.fetchUfs(); // Carregar os UFs ao montar o componente
    },
};
</script>

<style scoped>

input {
    text-transform: uppercase;
}

/* Remove text-transform para o campo de email */
input[type="email"] {
    text-transform: none;
}

</style>
