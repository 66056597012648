// src/store/index.js
import { createStore } from 'vuex';
import axios from '../plugins/axios'; // Importando a instância do Axios

export default createStore({
  state: {
    idToken: localStorage.getItem('idToken') || null,
  },
  mutations: {
    setToken(state, token) {
      console.log("setToken - mutations!")
      state.idToken = token;
      if (token) {
        console.log("setItem 1")
        localStorage.setItem('idToken', token);
      } else {
        console.log("removeItem 1")
        localStorage.removeItem('idToken');
      }
    },
    logout(state) {
      state.idToken = null;
      console.log("removeItem 2")
      localStorage.removeItem('idToken');
    },
  },
  actions: {
    async validateToken({ commit, state }) {
      if (!state.idToken) {
        console.log("idToken failed! redirect to login!");
        commit('logout');
        this.$router.push('/login');
      }

      try {
        // chamada para validação do token deixando o interceptor tomar a decisão pelo return do status http.
        const response = await axios.get(`${process.env.VUE_APP_URL_API}/api/sistema/validatejwt?token=${state.idToken}`);

        // if (response.data.status !== 200) {
        //   console.log('Acesso inválido!', response.data);
        //   commit('logout');
        //   this.$router.push('/login');
        // }
      } catch (error) {
        console.error('Erro ao validar token:', error);
        commit('logout');
        this.$router.push('/login');
      }
    },
  },
});
