<template>
  <div class="bg-primary" id="layoutAuthentication">
      <div id="layoutAuthentication_content">
          <main>
              <div class="container">
                  <div class="row justify-content-center">
                      <div class="col-lg-5">
                          <div class="card shadow-lg border-0 rounded-lg mt-5">
                              <div class="card-header">
                                  <h3 class="text-center font-weight-light my-4">Login</h3>
                              </div>
                              <div class="card-body">
                                  <form @submit.prevent="handleLogin">
                                      <div class="form-floating mb-3">
                                          <input class="form-control" id="inputEmail" type="email" v-model="email" placeholder="name@example.com" />
                                          <label for="inputEmail">E-mail</label>
                                      </div>
                                      <div class="form-floating mb-3">
                                          <input class="form-control" id="inputPassword" type="password" v-model="password" placeholder="Password" />
                                          <label for="inputPassword">Senha</label>
                                      </div>
                                      <div class="d-flex align-items-center justify-content-between mt-4 mb-0">
                                          <a class="small" href="password.html">Esqueci minha senha</a>
                                          <button class="btn btn-primary" type="submit">Login</button>
                                      </div>
                                  </form>
                              </div>
                              <div class="card-footer text-center py-3">
                                  <div class="small">
                                      <a href="register.html">Criar minha assinatura!</a>
                                  </div>
                              </div>
                          </div>
                      </div>
                  </div>
              </div>
          </main>
      </div>
<!-- 
      <div class="toast-container position-fixed bottom-0 end-0 p-3" style="z-index: 5">
        <div id="liveToast" class="toast" role="alert" aria-live="assertive" aria-atomic="true">
          <div class="toast-header">
            <img src="..." class="rounded me-2" alt="..." />
            <strong class="me-auto">Bootstrap</strong>
            <small>11 mins ago</small>
            <button type="button" class="btn-close" data-bs-dismiss="toast" aria-label="Close"></button>
          </div>
          <div class="toast-body">
            Hello, world! This is a toast message.
          </div>
        </div>
      </div>       -->
      

      <div id="layoutAuthentication_footer">
          <footer class="py-4 bg-light mt-auto">
              <div class="container-fluid px-4">
                  <div class="d-flex align-items-center justify-content-between small">
                      <div class="text-muted">Copyright &copy; DataTrading</div>
                      <div>
                          <a href="https://www.datatrading.com.br">datatrading.com.br</a>
                          &middot;
                          <a href="mailto:contato@datatrading.com.br">contato@datatrading.com.br</a>
                      </div>
                  </div>
              </div>
          </footer>
      </div>
  </div>
</template>

<script>
import { auth } from '../firebase';
import { onIdTokenChanged, signInWithEmailAndPassword } from "firebase/auth";
import store from '../store';

export default {
  name: 'LoginView',
  data() {
      return {
          email: '',
          password: '',
          rememberPassword: false
      };
  },
  methods: {
    handleLogin() {
      try {
        signInWithEmailAndPassword(auth, this.email, this.password)
        .then((userCredential) => {
          const user = userCredential.user;

          user.getIdToken().then((idToken) => {
            localStorage.setItem("idToken", idToken);
            localStorage.setItem("user", user.email);
            store.commit('setToken', idToken);
          });

          this.$router.push('/');
        })
        .catch((error) => {
          alert("Não foi possível efetuar o login na aplicação.")         
        });
      } catch (error) {
        alert(error.message);
      }

    }
  },
  mounted() {
    //localStorage.removeItem('idToken');
    store.commit("logout");
  }    
}
</script>

<style scoped>
@import url('https://use.fontawesome.com/releases/v6.3.0/css/all.css');
@import url('https://cdn.jsdelivr.net/npm/bootstrap@5.2.3/dist/css/bootstrap.min.css');

body {
  font-family: Arial, sans-serif;
}

.bg-primary {
  background-color: #007bff !important;
}

#layoutAuthentication {
  min-height: 100vh;
}

#layoutAuthentication_content {
  padding-top: 5rem;
}

.card {
  border-radius: 1rem;
}
</style>
